import de from './locales/de.json'
import fr from './locales/fr.json'
import it from './locales/it.json'
import en from './locales/en.json'

export default defineI18nConfig(() => ({
    legacy: false,
    messages: {
        de: de,
        fr: fr,
        it: it,
        en: en
    },
    datetimeFormats: {
        'de': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
        },
        'en': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
        },
        'fr': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
        },
        'it': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
        }
    }
}));